<template>
	<div class="fotter">
		<div class="fotter_content">
			<div class="fotter_log">
				<img :src="basedetail.web_site_logo" alt="">
			</div>
			<div class="fotter_list" @click="gonext(1)">
				<span>首页</span>
			</div>
			<div class="fotter_list">
				<span>在线课程</span>
				<ul>
					<li @click="erjiclick(21)">最新课程</li>
					<li @click="erjiclick(22)">视频课程</li>
					<li @click="erjiclick(23)">直播课程</li>
					<li @click="erjiclick(24)">系列课程</li>
				</ul>
			</div>
			<div class="fotter_list" @click="gonext(3)">
				<span>线下课程</span>
			</div>
			<div class="fotter_list" @click="gonext(4)">
				<span>平台专家</span>
			</div>
			<div class="fotter_list" @click="gonext(5)">
				<span>病例展示</span>
			</div>
			<div class="fotter_list" @click="gonext(6)">
				<span>在线商城</span>
			</div>
			<div class="fotter_list" @click="gonext(7)">
				<span>成为会员</span>
			</div>
			<div class="fotter_list">
				<span @click="gonext(8)">关于我们</span>
				<ul>
					<li @click="erjiclick(81)">平台介绍</li>
					<li @click="erjiclick(82)">常见问题</li>
					<li @click="erjiclick(83)">联系我们</li>
				</ul>
			</div>
			<div class="fotter_list_img">
				<img :src="basedetail.app_apk" alt="">
				<span>扫码下载app</span>
			</div>
			<div class="fotter_list_img">
				<img :src="basedetail.xcx_ercode" alt="">
				<span>{{basedetail.xcx_name}}</span>
			</div>
			<div class="footer_two">
				<p> <span @click.stop="openxieyi(2)">隐私政策</span>|<span @click.stop="openxieyi(1)">免责条款</span></p>
				<!-- <p>{{basedetail.qualification }}</p> -->
				<viewer :images="basedetail.qualification">
					<img v-for="item in basedetail.qualification" :src="item" alt="">
				</viewer>
				<p>{{basedetail.web_site_copyright}}</p>
			</div>
		</div>
		<!-- 隐私政策和注册协议 -->
		<div class="yszcxy" v-if="yszcxyshow">
			<div class="yszcxy_once">
				<div class="yszcxy_once_header">
					<span>{{xieyicontent.name}}</span>
					<img src="../assets/icon32.png" alt="" @click="yszcxyshow=false">
				</div>
				<div class="yszcxy_once_text">
					<span v-html="xieyicontent.content"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				basedetail:"",//网站基本信息
				yszcxyshow: false, //隐私政策协议弹窗
				xieyicontent:"",//协议详情数据
				opentype: 1, //区别注册和隐私协议
				oneurl:"",//获取的当前页面路径
			}
		},
		mounted() {
			this.oneurl = window.location.href;
			this.get_base();
		},
		methods:{
			//获取网站基本信息
			get_base(){
				this.$http({
					url: '60e561cd51d26',
					method: 'post',
					data:JSON.stringify({
						
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.basedetail = res.data.data;
					}
				});
			},
			
			//跳转下级页面(一级点击事件)
			gonext(type){
				this.headerindex=type;
				localStorage.setItem('headerindex',type);
				if(type==1){
					if(this.oneurl=="https://www.denlike.com/pcchina/#/"){
						window.location.reload();
					}else{
						this.$router.push({path:"/"})
					}
				}else if(type==2){
					// this.headerindex=2;
				}else if(type==3){
					//线下课程
					let pageurl = this.oneurl.split("/#/")[1];
					console.log(pageurl)
					if(pageurl =="OfflineCourses"){
						window.location.reload();
					}else{
						this.$router.push({path:"/OfflineCourses"})
					}
				}else if(type==4){
					//平台专家
					let pageurl = this.oneurl.split("/#/")[1];
					if(pageurl =="PlattformExperten"){
						window.location.reload();
					}else{
						this.$router.push({path:"/PlattformExperten"})
					}
				}else if(type==5){
					//病例展示
					let pageurl = this.oneurl.split("/#/")[1];
					if(pageurl =="Casepresentation"){
						window.location.reload();
					}else{
						this.$router.push({path:"/Casepresentation"})
					}
				}else if(type==6){
					//在线商城
					let pageurl = this.oneurl.split("/#/")[1];
					if(pageurl =="shopIndex"){
						window.location.reload();
					}else{
						this.$router.push({path:"/shopIndex"})
					}
				}else if(type==7){
					//成为会员
					let pageurl = this.oneurl.split("/#/")[1];
					if(pageurl =="memberVip"){
						window.location.reload();
					}else{
						this.$router.push({path:"/memberVip"})
					}
				}else if(type==8){
					this.$router.push({path:"/contactUs"})
				}
			},
			
			//二级点击事件
			erjiclick(index){
				localStorage.setItem("twiceindex",index);
				let pageurl = this.oneurl.split("/#/")[1];
				if(index==21){
					localStorage.setItem('headerindex',2);
					let newpageurl = pageurl.split("?")[0];
					if(newpageurl =="newcourses"){
						window.location.reload();
					}else{
						this.$router.push({path:"newcourses",query:{type:1}});
					}
					// this.$router.push({path:"newcourses",query:{type:1}});
				}else if(index==22){
					localStorage.setItem('headerindex',2);
					let newpageurl = pageurl.split("?")[0];
					if(newpageurl =="videocourses"){
						window.location.reload();
					}else{
						this.$router.push({path:"videocourses",query:{type:2}});
					}
					// this.$router.push({path:"videocourses",query:{type:2}});
				}else if(index==23){
					localStorage.setItem('headerindex',2);
					let newpageurl = pageurl.split("?")[0];
					if(newpageurl =="livecourses"){
						window.location.reload();
					}else{
						this.$router.push({path:"livecourses",query:{type:3}});
					}
					// this.$router.push({path:"livecourses",query:{type:3}});
				}else if(index==24){
					localStorage.setItem('headerindex',2);
					let newpageurl = pageurl.split("?")[0];
					if(newpageurl =="Latestcourses"){
						window.location.reload();
					}else{
						this.$router.push({path:"Latestcourses",query:{type:5}});
					}
					// this.$router.push({path:"Latestcourses",query:{type:5}});
				}else if(index==81){
					localStorage.setItem('headerindex',8);
					if(pageurl =="PlatformIntroduction"){
						window.location.reload();
					}else{
						this.$router.push({path:"PlatformIntroduction"});
					}
					//平台介绍
					// this.$router.push({path:"PlatformIntroduction"});
				}else if(index==82){
					localStorage.setItem('headerindex',8);
					//常见问题
					if(pageurl =="commonProblem"){
						window.location.reload();
					}else{
						this.$router.push({path:"commonProblem"});
					}
					// this.$router.push({path:"commonProblem"});
				}else if(index==83){
					localStorage.setItem('headerindex',8);
					//联系我们
					// this.$router.push({path:"contactUs"});
					if(pageurl =="contactUs"){
						window.location.reload();
					}else{
						this.$router.push({path:"contactUs"});
					}
				}
			},
			
			//查看协议
			openxieyi(type) {
				this.yszcxyshow = !this.yszcxyshow;
				this.opentype = type;
				this.$http({
					url: '62c393b950315',
					method: 'get',
					params: {
						aid: type,
						list_rows: "15",
					},
				}).then((res) => {
					if (res.data.code == 1) {
						this.xieyicontent = res.data.data;
					}
				});
			},
		}
	}
</script>

<style scoped>
	.fotter{
		width: 100%;
		overflow: hidden;
		/* background: #131313; */
		background: #5c5a59;
	}
	.fotter_content{
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
	}
	.fotter_log{
		width: 153px;
		height: 153px;
		float: left;
		margin-top: 32px;
	}
	.fotter_log img{
		width: 100%;
		height: 100%;
	}
	.fotter_list{
		width: auto;
		overflow: hidden;
		float: left;
		margin-top: 32px;
		font-size: 18px;
		font-weight: bold;	font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #BFBFBF;
		line-height: 24px;
	}
	.fotter_list{
		margin-left: 22px;
	}
	.fotter_list span:hover{
		color: #EB6A39;
	}
	.fotter_list ul{
		margin-top: 20px;
	}
	.fotter_list li{
		margin-top:8px;
		font-size: 14px;
		font-weight: normal;
		text-align: center;
	}
	.fotter_list li:hover{
		color: #EB6A39;
	}
	.fotter_list_img{
		width: 96px;
		overflow: hidden;
		float: left;
		margin-top: 32px;
		margin-left: 52px;
		font-size: 12px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #EEEEEE;
		text-align: center;
	}
	.fotter_list_img img{
		width: 100%;
		height: 96px;
		margin-bottom: 10px;
	}
	.footer_two{
		width: 1200px;
		overflow: hidden;
		clear: both;
		margin:0 auto;
		font-size: 12px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		line-height: 22px;
		text-align: center;
	}
	.footer_two p{
		margin-bottom: 10px;
	}
	.footer_two p:last-child{
		margin-bottom: 40px;
	}
	.footer_two p span{
		padding-left: 20px;
		padding-right: 20px;
	}
	.footer_two img{
		width: 96px;
		height: 96px;
	}
	.yszcxy {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 9;
	}
	
	.yszcxy_once {
		width: 1080px;
		height: 75%;
		background: #FFFFFF;
		margin: 5% auto;
	}
	
	.yszcxy_once_header {
		width: 1080px;
		height: 56px;
		background: #F6F6F6;
		font-size: 18px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		text-indent: 32px;
		line-height: 56px;
	}
	
	.yszcxy_once_header img {
		width: 20px;
		height: 20px;
		display: block;
		float: right;
		margin-top: 18px;
		margin-right: 32px;
	}
	
	.yszcxy_once_text {
		width: 1016px;
		height: 545px;
		overflow-y: scroll;
		margin: 32px auto;
	}
</style>